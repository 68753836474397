var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex" },
    [
      _vm.loginMainImage === "" ||
      _vm.loginMainImage === undefined ||
      _vm.loginMainImage === null
        ? _c("div", { staticClass: "bg" })
        : _c("div", {
            staticClass: "bg1",
            style: {
              background:
                "url(" + _vm.loginMainImage + ")no-repeat center center / cover"
            }
          }),
      _c(
        "div",
        { staticClass: "loginBox d-flex justify-center align-center" },
        [
          _vm.setFetchLanguageListflag
            ? _c("div", { staticClass: "language-dropdown" }, [
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.$t("Choose Your Language")) + " ")
                ]),
                _c("div", { staticClass: "h_lang drop-down" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-dropdown",
                      attrs: { type: "button" },
                      on: { click: _vm.toggleDropdown }
                    },
                    [_vm._v(" " + _vm._s(_vm.currentLanguage) + " ")]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "drop-box custom-scrollBox",
                      staticStyle: { height: "200px", "overflow-y": "hide" }
                    },
                    _vm._l(_vm.languageList, function(language) {
                      return _c(
                        "li",
                        {
                          key: language.id,
                          staticStyle: { cursor: "pointer", padding: "10px" },
                          style: {
                            backgroundColor:
                              _vm.currentLanguage === language.languageName
                                ? "#C1C1C1"
                                : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.setActive(language)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(language.languageName) + " ")]
                      )
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "v-card",
            { staticClass: "loginForm", attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "login-tit justify-center" }, [
                _vm._v(_vm._s(_vm.$t("ESL DASHBOARD")))
              ]),
              _c(
                "v-card-subtitle",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.keycloakloginform,
                      expression: "!keycloakloginform"
                    }
                  ],
                  staticClass: "login-txt mt-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Welcome back! Please login to your account.")
                    )
                  )
                ]
              ),
              _c(
                "v-card-subtitle",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.keycloakloginform,
                      expression: "keycloakloginform"
                    }
                  ],
                  staticClass: "login-txt mt-1",
                  staticStyle: { "text-align": "center" }
                },
                [_vm._v(_vm._s(_vm.$t("Welcome back!")))]
              ),
              _c(
                "v-card-text",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.keycloakloginform,
                      expression: "!keycloakloginform"
                    }
                  ],
                  staticClass: "mt-7"
                },
                [
                  _c(
                    "v-form",
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          hint: _vm.loginMode.code + ", " + _vm.loginMode.name,
                          items: _vm.loginModeItems,
                          "item-text": "name",
                          "item-value": "code",
                          "return-object": "",
                          outlined: "",
                          dense: "",
                          solo: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "0.8125rem" } },
                                  [_vm._v(_vm._s(_vm.$t(item.name)))]
                                )
                              ]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "LedPatternClass",
                                    staticStyle: {
                                      display: "contents !important"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t(item.name)))]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.loginMode,
                          callback: function($$v) {
                            _vm.loginMode = $$v
                          },
                          expression: "loginMode"
                        }
                      }),
                      _c("v-text-field", {
                        ref: "account",
                        staticClass: "form-login",
                        attrs: { label: _vm.$t("ID"), clearable: "" },
                        model: {
                          value: _vm.user.account,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "account", $$v)
                          },
                          expression: "user.account"
                        }
                      }),
                      _c("v-text-field", {
                        ref: "password",
                        staticClass: "form-login",
                        attrs: {
                          label: _vm.$t("Password"),
                          type: "password",
                          clearable: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onLogin($event)
                          }
                        },
                        model: {
                          value: _vm.user.password,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "password", $$v)
                          },
                          expression: "user.password"
                        }
                      }),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.domainInputShow,
                            expression: "domainInputShow"
                          }
                        ],
                        staticClass: "form-login",
                        attrs: { label: "Domain", clearable: "" },
                        model: {
                          value: _vm.user.domain,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "domain", $$v)
                          },
                          expression: "user.domain"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center"
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "form-checkbox _login",
                            attrs: {
                              label: _vm.$t("Stay Signed In"),
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.staySignedIn,
                              callback: function($$v) {
                                _vm.staySignedIn = $$v
                              },
                              expression: "staySignedIn"
                            }
                          }),
                          !_vm.domainInputShow
                            ? _c(
                                "v-dialog",
                                {
                                  attrs: { width: "500" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "link",
                                                    attrs: { text: "" }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Forgot Password?")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3327526271
                                  ),
                                  model: {
                                    value: _vm.dialog,
                                    callback: function($$v) {
                                      _vm.dialog = $$v
                                    },
                                    expression: "dialog"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "popup add_store_popup" },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "page-title-bar" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "25",
                                                color: "#001e38"
                                              }
                                            },
                                            [_vm._v("mdi-lock")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Forgot your Password?")
                                            ) + " "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mt-5" },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "form-input",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  clearable: "",
                                                  label: "User ID*"
                                                },
                                                model: {
                                                  value: _vm.account,
                                                  callback: function($$v) {
                                                    _vm.account = $$v
                                                  },
                                                  expression: "account"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn mt-10",
                                              staticStyle: {
                                                "line-height": "37px"
                                              },
                                              attrs: { text: "", icon: "" },
                                              on: { click: _vm.passwordReset }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Send Email"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.passwordText) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-actions",
                                        {
                                          staticClass: "d-flex justify-center"
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn",
                                              staticStyle: {
                                                "line-height": "37px"
                                              },
                                              attrs: { text: "", icon: "" },
                                              on: {
                                                click: _vm.closePasswordPopup
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Close")))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.licenseExpired
                ? _c("p", { staticClass: "txt-activation" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/loginerror.png"),
                        alt: ""
                      }
                    }),
                    _vm._v(" License has expired. Please activate license. ")
                  ])
                : _vm._e(),
              _vm.licenseExpired
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-activation",
                      attrs: { text: "" },
                      on: { click: _vm.showLoginLicenseActivation }
                    },
                    [_vm._v("License Activation ")]
                  )
                : _vm._e(),
              _c("license-activation", {
                attrs: { "license-expired": _vm.licenseExpired },
                on: {
                  messageAlert: _vm.messageAlert,
                  hideActivateLicenseBtn: _vm.hideActivateLicenseBtn
                }
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.passwordExpired,
                      expression: "passwordExpired"
                    }
                  ],
                  staticClass: "txt-activation"
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/loginerror.png"),
                      alt: ""
                    }
                  }),
                  _vm._v(" User's password has expired. ")
                ]
              ),
              _vm.passwordExpired
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-activation",
                      on: { click: _vm.showLoginPasswordChange }
                    },
                    [_vm._v(_vm._s(_vm.$t("Change Password")))]
                  )
                : _vm._e(),
              _c("password-change", {
                attrs: {
                  account: _vm.passwordExpiredAccount,
                  passwordexpired: _vm.passwordExpired
                },
                on: {
                  messageAlert: _vm.messageAlert,
                  hideChangePasswordBtn: _vm.hideChangePasswordBtn
                }
              }),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.keycloakloginform,
                      expression: "!keycloakloginform"
                    }
                  ],
                  staticClass: "btn-login",
                  on: { click: _vm.onLogin }
                },
                [_vm._v(_vm._s(_vm.$t("Login")))]
              )
            ],
            1
          ),
          _c("p", { staticClass: "copyright" }, [
            _vm.loginLogoImage === "" ||
            _vm.loginLogoImage === undefined ||
            _vm.loginLogoImage === null
              ? _c("span", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/logo-login.jpg"),
                      alt: "solum"
                    }
                  }),
                  _c("br"),
                  _vm._v(
                    _vm._s(
                      _vm.$t("@2015-2022 SOLUM Proprietary and Confidential")
                    ) + " "
                  )
                ])
              : _c("span", [
                  _c("img", {
                    staticStyle: { width: "148px", height: "26px" },
                    attrs: { src: _vm.loginLogoImage, alt: "solum" }
                  }),
                  _c("br"),
                  _vm._v(
                    _vm._s(
                      _vm.$t("@2015-2022 SOLUM Proprietary and Confidential")
                    )
                  )
                ])
          ])
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "300" },
              model: {
                value: _vm.messageDialog,
                callback: function($$v) {
                  _vm.messageDialog = $$v
                },
                expression: "messageDialog"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "popup add_store_popup",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.messageText) }
                        })
                      ])
                    ],
                    1
                  ),
                  !_vm.okBtn
                    ? _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "", "max-width": "100" },
                              on: { click: _vm.doForceLogin }
                            },
                            [_vm._v(_vm._s(_vm.$t("Yes")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "", "max-width": "100" },
                              on: {
                                click: function($event) {
                                  _vm.messageDialog = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Cancel")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.okBtn
                    ? _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.messageDialog = false
                                  _vm.keycloakRedirect()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("OK")))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("loading-box", { attrs: { loadingBox: _vm.loadingBox } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }